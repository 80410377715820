import React, {useState} from "react";
import i18next from "../i18n";
import "../css/User_Saerch.css";

const User_Saerch = () => {
    const [list, setList] = useState<Array<any>>([]);
    const [show, setShow] = useState(false);

    const loadList = (input: string) => {
        if( input.trim().length > 2 ) {
            socket.send("findUsername", input, (response: []) => {
                setList(response);
                setShow(true);
            });
        }else{
            setShow(false);
        }
    }

    return (
        <div className="user_saerch_content">
            <input
                type="text"
                placeholder={i18next.t('user.name').toString()}
                onChange={e => loadList(e.target.value)}
            />
            <div className="list_content" style={{display: show?"block":"none"}}>
                {list.map( (user: any, i: number) => (
                    <p key={i} onClick={ () => {socket.send('open qry', user.user_id)} }><span>{user['username']}</span></p>
                ))}
            </div>
        </div>
    )
}

export default User_Saerch;