import * as React from "react";
import "../css/friends.css";
import Friends_list from "./friends_list";

const Friends = ({data, users} : {data: any, users: any[]}) => {
    return (
        <div id="friends_Content">
            <div id="friendsOverview">
                <Friends_list data={data} users={users}/>
            </div>
        </div>
    );
}

export default Friends;