import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import TRANSLATIONS_DE from "./i18n/de.json";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            de: {
                translation: TRANSLATIONS_DE
            }
        }
    });

i18n.changeLanguage("de");

export default i18n;