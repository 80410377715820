import React, {useEffect, useRef, useState} from 'react'
import "../css/ChatBody.css";
import {getDatetime, isCurrentRoom, notice, replacen} from "../../service/function";
import parser from 'bbcode-to-react';
import {Scrollbars} from "react-custom-scrollbars-2";
import i18next from "../../i18n";
import {Socket} from "socket.io-client";
import MessageResponse from "../../service/MessageResponse";

const ChatBody = ({socketClass, msgs} : {socketClass: any,msgs:Array<any>}) => {
    const socket: Socket = socketClass.getSocket();
    const [messages, setMessages] = useState<Array<any>>([]);
    const [typingStatus, setTypingStatus] = useState("");
    const lastMessageRef = useRef<any>(null);

    useEffect(() => {
        setMessages(msgs);
    },[msgs]);


    useEffect(()=> {
        MessageResponse.add("ChatBody",  (messages:any, message: any, room: roomResponse) => {
            if ( isCurrentRoom(room) ) {
                setMessages([...messages, message]);
            }
        }, messages);
    }, [socket, messages]);

    useEffect(()=> {
        socket.on("typingResponse", (username: string, room: roomResponse) => {
            if (
                ( (socketClass.getRoomId() == room.id && socketClass.getRoomType() == room.type) ||
                    (room.type == 2 && socketClass.getRoomType() == 2 && socketClass.getRoomType() == room.type) ) && username !== socketClass.getUserUsername()
            ) {
                setTypingStatus( i18next.t('typingResponse', { username: username }).toString() ); //username
                setTimeout(() => { setTypingStatus(""); }, 2500);
            }
        });

        return () => {
            socket.off('typingResponse');
        };
    }, []);

    useEffect(() => {
        lastMessageRef.current.scrollToBottom();
    }, [messages]);

    return (
        <>
            <Scrollbars
                ref={lastMessageRef}
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
            >
                <div className='message__container' id="lobby">
                    {messages.map((msg, i) => (
                        <div className="message__chats" key={i}>
                            <h1>{msg.username} <span>{getDatetime(msg.datum)}</span></h1>
                            <div className='message__recipient'>
                                <p className='message_point'>{parser.toReact( replacen(msg.text) )}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </Scrollbars>
            <div className='typing__status'>
                <p>{typingStatus}</p>
            </div>
        </>
    )
}

export default ChatBody