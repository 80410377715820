import React, {useEffect, useRef, useState} from 'react';
import '../css/Game.css';
import '../css/menue.css';
import i18next from "../i18n";
import MessageResponse from "../service/MessageResponse";
import {setSessStore} from "../service/storage";

const Game = ({socketClass, unread} : {socketClass:any, unread:any}) => {
    const [navbar, setNavbar] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const [unreadStec, setUnreadStec] = useState(unread['stec']);

    const [counter, setCounter] = useState(0);
    const communicator = document.getElementById('communicator') as HTMLElement;

    useEffect(() => {
        const handler = (event: any) => {
            if (
                navbar &&
                ref.current &&
                !ref.current.contains(event.target)
            ) {
                setNavbar(false);
            }
        };

        document.addEventListener('mousedown', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, [navbar]);

    useEffect(()=> {

        MessageResponse.add("menue", (data: any, message: any, room: roomResponse) => {
            if ( socketClass.getUserId() == null ) {
                setSessStore("message", {msgs: message, room: room});
            }

            if ( socketClass.getRoomId() !== room.id || socketClass.getRoomType() !== room.type ) {
                switch (room.type) {
                    case 1:
                        unreadStec['ally']++;
                        break;
                    case 2:
                        unreadStec['user']++;
                        break;
                    default:
                        unreadStec['rooms']++;
                        break;
                }
            }
        });

    }, [navbar, unreadStec]);

    const loadRoom = (id: any, type: number) => {
        socketClass.send('switch room', id, type);
        toogle();
    };

    const loadIgmList = () => {
        socketClass.send('igmList');
        toogle();
    }

    const loadFriendList = () => {
        socketClass.send('friends');
        toogle();
    }

    const guestNav = () => {
        return (
            <>
                <li className={unreadStec['rooms']>0?"unread_messages":""} onClick={() => loadRoom(1, 0)}><span>{i18next.t('navigation.lobby')}</span></li>
                <li><span>{i18next.t('navigation.login')}</span></li>
            </>
        );
    }

    const userNav = () => {
        const nav = socketClass.getUser().ally_id === null ? "":<li className={unreadStec['ally']>0?"unread_messages":""} onClick={() => loadRoom(socketClass.getUser().ally_id, 1)}><span>{i18next.t('navigation.ally')}</span></li>;

        return (
            <>
                <li className={unreadStec['rooms']>0?"unread_messages":""} onClick={() => loadRoom(1, 0)}><span>{i18next.t('navigation.lobby')}</span></li>
                {nav}
                <li className={unreadStec['pv']>0?"unread_messages":""} onClick={ () => { loadIgmList(); } }><span>{i18next.t('navigation.igms')}</span></li>
                <li onClick={ () => { loadFriendList(); } }><span>{i18next.t('navigation.friends')}</span></li>
            </>
        );
    }

    const getMenue = () => {
        return (
            <nav className="navbar" id="navbar">
                <div id="comminiNav" ref={ref} onClick={() => setNavbar((prev) => !prev)} >
                    <div id="chatComm">
                        <img src="https://static.dark-nights.net/img/games/dc/button/igm.png"  alt={i18next.t('chat').toString()}/>
                        <div id="chatUnread">{counter}</div>
                    </div>
                    <div className='game_menue_header'>
                        <nav className="navbar" id="navbar">
                            <ul className={`menu-nav${navbar ? ' show-menu' : ''}`}>
                                { socketClass.getUserId() !== null?userNav():guestNav()}
                            </ul>
                        </nav>
                    </div>
                </div>
            </nav>
        )
    }

    const toogle = () => {
        socketClass.getSocket().emit('messageRead');
        communicator.style.display = 'block';
    }

    const getUnread = () => {
        setInterval(() => {
            socketClass.getSocket().emit('getUnreadMessages', (count: number) => {
                setCounter(count);
            });
        }, 3000);
    }

    useEffect(() => {
        socketClass.getRespo("unreadMessengs", (count: number) => {
            setCounter(count);
            //getUnread();
        });

        return () => {
            socketClass.getSocket().off('unreadMessengs');
        };
    }, []);

    /*
            <>
            <div id="chatComm" onClick={ () => toogle() }>
                <img src="https://static.dark-nights.net/img/games/dc/button/igm.png"  alt={i18next.t('chat').toString()}/>
                <div id="chatUnread">{counter}</div>
            </div>
        </>
     */
    return (
        <>
            {getMenue()}
        </>
    );
};

export default Game;