import React from 'react';
import "../css/Chat.css";
import ChatBody from "./ChatBody";
import ChatFooter from "./ChatFooter";
import UserList from "./ChatUserList";

const Chat = ({socketClass, userlistShow, data} : {socketClass:any, userlistShow: boolean,data:any}) => {

const getUserlist = () => {
    if ( socketClass.getRoomType() === 2 ) {
        return false;
    } else if ( window.innerWidth > 1023 ) {
        return true;
    }

    return userlistShow && socketClass.getRoomType()!==2;
}
    return (
        <div id="chatLobby">
            {data['room']['type'] === 2?<></>:
                <div id='lobby_users' className={`user-nav ${userlistShow && socketClass.getRoomType()!==2 ? 'show-user' : 'show-user-none'}`}>
                    <UserList socketClass={socketClass} userlist={data.userlist} />
                </div>}
            <div className={`user-nav ${getUserlist() ? 'lobby_user_list_show' : 'lobby_user_list_none'}`} id="lobbyContent">
                <ChatBody socketClass={socketClass} msgs={data['msgs']}/>
            </div>
            <ChatFooter socketClass={socketClass} userlistShow={userlistShow}/>
        </div>
    );
};

export default Chat;
