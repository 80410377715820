import React, { useEffect, useState} from 'react';
import './App.css';
import Chat from "./chat/ts/Chat";
import i18next from "./i18n";
import Igm from "./messages/ts/Igm";
import {array_values} from "./service/function";
import Friends from "./friends/ts/friends";
import {getSessStore} from "./service/storage";
import {Socket} from "socket.io-client";

const App = ({socketClass, data} : {socketClass:any, data: any}) => {
    const [response, setResponse] = useState<any>(data);

    const socket: Socket = socketClass.getSocket();
    const [userlistShow, setUserlist] = useState(false);
    const [area, setArea] = useState<number>(0);
    const [users, setUsers] = useState<Array<any>>([]);

    useEffect(()=> {
        socketClass.getRespo("chat room switch", (msgs: any, room: roomResponse, userlist: any) => {
            if ( socketClass.getUserId() == null && msgs.length < 1 ) {
                msgs = getSessStore("message", room);
                if (msgs == null) {
                    msgs = [];
                }
            }
            setResponse({msgs:msgs, userlist:userlist, room:room});
            socketClass.setRoom(room.id, room.type);
            if ( room.type == 2 ) {
                setUserlist(false);
            }
            setArea(0);
        });

        socketClass.getRespo("igmList", (msgs: any, users: []) => {
            setResponse({
                list:array_values(msgs).sort((a: any, b: any) => a.stamp < b.stamp ? 1 : -1),
                    room:i18next.t('navigation.igms').toString(),
                    users:users
                });
            setUsers(users);
            setArea(1);
        });

        socketClass.getRespo("friends", (list: any, users: []) => {
            setResponse({
                list:list,
                room:i18next.t('navigation.friends').toString(),
                users:users
            });
            setUsers(users);
            setArea(2);
        });

        socketClass.getRespo("liveList", (users: []) => {
            setUsers(users);
        });

        return () => {
            socket.off('chat room switch');
            socket.off('igmList');
            socket.off('liveList');
            socket.off('friends');
        };
    }, [response, area, users, userlistShow]);

    const getAreaTemp = () => {
        if ( area === 2 ) {
            return (<Friends data={response} users={users}/>);
        }else if ( area === 1 ) {
            return (<Igm socketClass={socketClass} data={response} users={users} />);
        }else{
            return (<Chat socketClass={socketClass} userlistShow={userlistShow} data={response}/>);
        }
    };

    const getOrder = () => {
        return typeof response['room'] !== "string" ? response['room']['name'] : response['room'];
    }

  return (
      <>
          <div className='chat_header'> <span>{getOrder()}</span>
              {
                  !userlistShow? <svg onClick={() => setUserlist((prev) => !prev)} xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"
                                     className="bi bi-person-fill-up" viewBox="0 0 16 16">
                      <path
                          d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.354-5.854 1.5 1.5a.5.5 0 0 1-.708.708L13 11.707V14.5a.5.5 0 0 1-1 0v-2.793l-.646.647a.5.5 0 0 1-.708-.708l1.5-1.5a.5.5 0 0 1 .708 0ZM11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                      <path
                          d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z"/>
                  </svg>: <svg onClick={() => setUserlist((prev) => !prev)} xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"
                               className="bi bi-person-fill-down" viewBox="0 0 16 16">
                      <path
                          d="M12.5 9a3.5 3.5 0 1 1 0 7 3.5 3.5 0 0 1 0-7Zm.354 5.854 1.5-1.5a.5.5 0 0 0-.708-.708l-.646.647V10.5a.5.5 0 0 0-1 0v2.793l-.646-.647a.5.5 0 0 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0ZM11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                      <path
                          d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z"/>
                  </svg>
              }
          </div>
          <div className='chat_body'>
              {getAreaTemp()}
          </div>
      </>
  );
};

export default App;
