import addNotification from "react-push-notification";
import i18next from "../i18n";

export const getDatetime = (timestamp = null) => {
    let currentdate = typeof timestamp=="number"?new Date(timestamp * 1000):new Date();

    return (currentdate.getDate()<10?'0':'') + currentdate.getDate() + "."
        + ((currentdate.getMonth()+1)<10?'0':'') + (currentdate.getMonth()+1) + "."
        + currentdate.getFullYear() + " "
        + (currentdate.getHours()<10?'0':'') + currentdate.getHours() + ":"
        + (currentdate.getMinutes()<10?'0':'') + currentdate.getMinutes() + ":"
        + (currentdate.getSeconds()<10?'0':'') + currentdate.getSeconds();
}

export const array_values = (obj: any) => {
    const aux = [];
    for (let i in obj) {
        if (obj.hasOwnProperty(i)) {
            aux.push(obj[i]);
        }
    }

    return aux;
}

export const replacen = (message: string) => {
    message = message.replace(/<br[^>]*>|<[^<]*b>/g, "\n").replace("{FORWARDING}", "[quote]").replace("{/FORWARDING}", "[/quote]");
    return message.replace(/\[(\w+)[^\]]*](.*?|)\[\/\1]|(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig, function (url, data) {
        if (data === undefined) {
            return `[url]${url}[/url]`;
        } else if (data.toLowerCase() == "url") {
            return checkEigenURL(url);
        }

        return `[url]${url}[/url]`;// checkEigenURL(url);//'<a style="color: #9fff00; font-weight: bold" href="'+ret+'" target="_blank">'+view+'</a>'
    });
    //return message.replace(/<br[^>]*>|<[^<]*b>/g, "\n");
}
export const linkify = (text: string) => {
    console.log( text );
    let urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    let data = text.replace(urlRegex, function(url) {
        console.log( urlRegex );
        console.log( url );
        return `[url]${url}[/url]`;// checkEigenURL(url);//'<a style="color: #9fff00; font-weight: bold" href="'+ret+'" target="_blank">'+view+'</a>'
    });//\[(\w+)[^\]]*](.*?)\[\/\1]
    console.log( data );
    console.log(
        data
            .replace("[url][url]", "[url]")
            .replace("[/url][/url]", "[/url]")
            .replace("[url=[url]", "[url=")
            .replace("[/url]]", "]")
    );
    console.log( data.replace("[url=[url]", "[url=") );
    console.log( data.replace("[/url]]", "]") );

    return data;
}

const checkEigenURL = (string: string) => {
    let ret = '';
    let view = '';
    let data = string.replace(/\[\/?[^\]]*\]/g, '').split('|', 2);
    if ( data.length <= 1 ) {
        return string;
    }
    switch ( data[0].toLowerCase() ) {
        case 'wiki':
            ret = ( !data[1] ) ? 'https://wiki.dark-nights.net/index.php?title=Dark-Conflict':'https://wiki.dark-nights.net/index.php?title='+data[1];
            view = ( !data[1] ) ? data[0]:data[1];
            break;
        default:
            ret = data[0];
            view = ( !data[1] ) ? data[0]:data[1];
            break;
    }

    view = view.replace("_%26_", " ");

    let result = ret.search ("http://");
    if( result == -1 ){
        let result1 = ret.search ("https://");
        if( result1 == -1 ){
            ret = "https://"+ret;
        }
    }

    return `[url=${ret}]${view}[/url]`;//'<a style="color: #9fff00; font-weight: bold" href="'+ret+'" target="_blank">'+view+'</a>';
}

export const Notification = (title: string, message: string, subtitle?: string, eventHandler?: any) => {

    addNotification({
        title: title,
        subtitle: subtitle,
        message: message,
        theme: 'light',
        closeButton:"X",
        backgroundTop:"green",
        backgroundBottom:"yellowgreen",
        duration: 5000,
        native: true,
        onClick:() => () => {
            if (eventHandler) {
                eventHandler();
            }
        }
    });
}

export const notice = (data: any, room: roomResponse, callback?: any) => {
    let title = data.username === room.name?"notification.title_small":"notification.title";

    addNotification({
        title: i18next.t(title, { username: data.username, room: room.name }).toString(),
        subtitle: i18next.t('notification.subtitle').toString(),
        message: data.text.length>40?data.text.substring(0, 40):data.text,
        theme: 'light',
        closeButton:"X",
        backgroundTop:"green",
        backgroundBottom:"yellowgreen",
        duration: 5000,
        native: true,
        onClick:() => {
            if (callback) {
                callback();
            }else{
                socket.send('switch room', room.id, room.type);
            }
        }
    });
}

export const isCurrentRoom = (room: roomResponse) => {
    if (room == null) {
        return true;
    }

    return socket.getRoomId() === room.id && socket.getRoomType() === room.type;
}