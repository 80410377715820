import React, { useEffect, useState, useRef} from 'react';
import './App.css';

const Error = ({err}: {err:Error}) => {
    return (
        <div id="chatLobby">
            <p>{err.message}</p>
        </div>
    );
};

export default Error;
