import React from "react";
import "../css/friends_list.css";
import {getDatetime} from "../../service/function";
import {Scrollbars} from "react-custom-scrollbars-2";

const FriendList = ({data, users} : {data: any, users: any[]}) => {

    const loadIgm = (id: number) => {
        socket.send('open qry', id);
    };

    return (
        <Scrollbars style={{ width: "100%", height: "100%" }}>
            <div className='message__container'>
                {data['list'].map( (friend: any, i:number) => (
                    <div className="message__chats" onClick={() => loadIgm(friend.other_id)} key={i}>
                        <div className="user_ico">
                            <span className={`users_list ${users[friend.id]!== undefined?'on':'off'}`}></span>
                            <img className="Profilbild_ico" src={`https://static.dark-nights.net/img/start/avatar/${friend.avatar}`}  alt={friend.username}/>
                        </div>
                        <div className="user_info">{friend.username} <span>{getDatetime(friend.stamp)}</span></div>
                    </div>
                ))}
            </div>
        </Scrollbars>
    );
}

export default FriendList;