import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Error from './error';
import reportWebVitals from './reportWebVitals';
import "./i18n";
import Game from "./components/Game";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const frame = document.getElementById('application') as HTMLElement;

const rendern = (data: any, to?: ReactDOM.Root) => {
    to=!to?root:to;
    to.render(
        <React.StrictMode>
                {data}
        </React.StrictMode>
    );
}

(async () => {
    await socket.initialize();

    socket.getSocket().on('connect', () => {
        socket.getRespo('auth_response', (user: any) => {
            socket.getSocket().emit('chat load', (data: any, msgs: any, room: roomResponse, userlist: any, unread: any) => {
                socket.setRoom(room.id, room.type);

                if ( frame !== null ) {
                    rendern(<Game socketClass={socket} unread={unread}/>, ReactDOM.createRoot(frame));
                }

                rendern(<App socketClass={socket} data={{msgs:msgs, userlist:userlist, room:room}}/>);
            });
        });
    }).on('connect_error', (err: any) => {
        rendern(<Error err={err}/>);
    });
})()

reportWebVitals();