import React, {useState} from 'react'
import '../css/ChatFooter.css';
import i18next from '../../i18n';


const ChatFooter = ({socketClass, userlistShow} : {socketClass:any, userlistShow: boolean}) => {
    const [message, setMessage] = useState("");
    const handleTyping = (e: any) => {
        if( e.which !== 10 && e.shiftKey !== true && e.which !== 13 ) {
            socketClass.getSocket().emit("typing");
        }
    };

    const handleSendMessage = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        if( message.trim() ) {
            socketClass.sendMessage(message)
        }
        setMessage("")
    }

    const sendMessKeypress = (e: any) => {
        if( e.which === 10 || e.which === 13 ) {
            if( e.shiftKey === false ) {
                e.preventDefault();
                handleSendMessage(e);
            }
        }
    };

    return (
        <div id='lobby_messenge' className={`${userlistShow && socketClass.getRoomType()!==2 ? 'lobby_user_list_show' : 'lobby_user_list_none'}`}>
            <form className='form' onSubmit={handleSendMessage}>
                <textarea
                    placeholder={i18next.t('footer.messagePlaceholder').toString()}
                    className='message'
                    id="mess"
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    onKeyDown={handleTyping}
                    onKeyUp={sendMessKeypress}
                />
                <span title={i18next.t('footer.send').toString()} className="sendBtn" id="sending" onClick={handleSendMessage}>➤</span>
            </form>
        </div>
    )
}

export default ChatFooter