export const setSessStore = (key: string, value: any) => {
    if ( key === "message" ) {
        setMessages(value);
    }else{
        window.sessionStorage.setItem(key, value);
    }
}

const setMessages = (value: any) => {
    let room: roomResponse = value.room;
    let msgs = value.msgs;
    let index = `${room.id}_${room.name}`;

    let store = getMessages();

    if ( store == null ) {
        store = {};
    }

    if ( !store[index] ) {
        store[index] = [msgs];
    }else{
        store[index].push(msgs);
    }

    window.sessionStorage.setItem("message", JSON.stringify(store));
}

export const getSessStore = (key: string, value?: any) => {
    if ( key === "message" ) {
        return getMessages(value);
    }

    return window.sessionStorage.getItem(key);
}

const getMessages = (value?: roomResponse) => {
    let mess = window.sessionStorage.getItem("message");

    if (mess == null) {
        return null;
    }

    let respo = JSON.parse(mess);

    if ( value ) {
        let index = `${value.id}_${value.name}`;
        if (respo[index]) {
            return respo[index];
        }

        return null;
    }

    return respo;
}