import {isCurrentRoom, notice, Notification} from "./function";
import addNotification from "react-push-notification";
import i18next from "../i18n";

class MessageResponse {
    __callbacks: any = {};
    private html: null | HTMLElement = null;

    constructor() {
        this.html = document.getElementById('communicator') as HTMLElement;
        this.create();
    }

    add(name: string, callback: any, data?:any) {
        this.create();
        this.__callbacks[name] = {callback:callback, data:data};
    }

    load(response: any) {
        for (const callback in this.__callbacks) {
            const current = this.__callbacks[callback];
            current['callback'](current['data'], ...response);
        }
    }

    isDisplay() {
        if ( this.html == null ) {
            return true;
        }

        return this.html.style.display == "block";
    }

    handler(room: roomResponse) {
        if ( this.html !== null ) {
            this.html.style.display = "block";
        }

        socket.send('switch room', room.id, room.type);
    }

    checkNotice(response: any) {
//data: any, room: roomResponse
        if (
            ( !this.isDisplay() || !isCurrentRoom(response[1]) ) &&
            ( response[0].user_id != socket.getUserId() && response[0].user_id != socket.getUserId() && socket.getUserId() != null )
        ) {
            let title = response[0].username === response[1].name?"notification.title_small":"notification.title";
            Notification(
                i18next.t(title, { username: response[0].username, room: response[1].name }).toString(),
                i18next.t('notification.subtitle').toString(),
                response[0].text.length>40?response[0].text.substring(0, 40):response[0].text,
                () => {
                    console.log("hi");
                    this.handler(response[1]);
                }
            );
            //event: socket.send('switch room', room.id, room.type);
        }
    }

    create() {
        if ( socket.getSocket() !== undefined ) {
            socket.getSocket().off("chat message");
            socket.getSocket().on("chat message", (...response: any) => {
                this.checkNotice(response);
                this.load(response);
            });
        }
    }
}

export default new MessageResponse();