import React, {useEffect, useState} from 'react'
import "../css/userlist.css";
import {Socket} from "socket.io-client";
import {Scrollbars} from "react-custom-scrollbars-2";

const UserList = ({socketClass, userlist} : {socketClass: any, userlist:any}) => {
    const socket: Socket = socketClass.getSocket();
    const [users, setUsers] = useState<Array<any>>(userlist);

    useEffect(()=> {
        socket.on("userListUpdate", (data: any, room: any) => {
            if ( socketClass.getRoomId() == room.id && socketClass.getRoomType() == room.type ) {
                setUsers( data.sort((a: any, b: any) => a.right_id > b.right_id ? 1 : -1) );
            }
        });

        return () => {
            socket.off("userListUpdate");
        };
    }, [socket, users]);

    const createQuery = (user: any) => {
        if ( user.user_id !== socketClass.getUserId() ) {
            let id = user.user_id == 0 || user.user_id == null?user.id:user.user_id;
            socketClass.send('open qry', id);
        }
    }

    return (
        <Scrollbars style={{ width: "100%", height: "100%" }}>
            <div id='lobby_users_list'>
                {users.map( (user, i) => <div key={i}>
                        <span className={`users_list ${user.afk?'afk':'on'}`}></span>
                        <p onClick={() => createQuery(user)} className={`userRight_${user.right_id} ${user.user_id == socketClass.getUserId()?'':'createQuery'}`} key={user.socketID}>{user.username}</p>
                    </div>
                )}
            </div>
        </Scrollbars>
    )
}

export default UserList