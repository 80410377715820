import React from "react";
import "../css/Igm.css";
import { Scrollbars } from 'react-custom-scrollbars-2';
import {getDatetime} from "../../service/function";
import User_Saerch from "../../components/User_Saerch";

const Igm = ({socketClass, data, users} : {socketClass:any, data: any, users: any[]}) => {

    const loadIgm = (id: number) => {
        socketClass.send('open qry', id);
    };

    return (
        <div id="Igm_Content">
            <User_Saerch />
            <div id="igmOverview">
                <Scrollbars style={{ width: "100%", height: "100%" }}>
                        <div className='message__container'>
                            {data['list'].map( (msg: any, i: number) => (
                                <div className={`message__chats ${msg.is?"unraed":""}`} onClick={() => loadIgm(msg.id)} key={i}>
                                    <div className="user_ico">
                                        <span className={`users_list ${users[msg.id]!== undefined?'on':'off'}`}></span>
                                        <img className="Profilbild_ico" src={`https://static.dark-nights.net/img/start/avatar/${msg.avatar}`} />
                                    </div>
                                    <div className="user_info">{msg.nickname} <span>{getDatetime(msg.stamp)}</span></div>
                                </div>
                            ))}
                        </div>
                    </Scrollbars>
                </div>
            </div>
    );
}

export default Igm;